<template>
  <div class="pageBox">
    <div class="content">
      <h3 class="title">
        <p class>金标认证</p>
        <svg class="msgIcon" aria-hidden="true" @click="lookStep()">
          <use xlink:href="#iconwenhaoxiao" />
        </svg>   
      </h3>
      <div class="applicationBox">
        <div class="searchBox">
          <div class="flex1">
            <el-input class="searchBtn" placeholder="按应用名称、应用包名搜索应用"  clearable v-model.trim="keyword" @keyup.enter.native="search()" @change="onEmpty()">
              <el-button class="fontSize18 searchIcon" slot="append" icon="el-icon-search" @click="search()" :disabled="this.keyword==''"></el-button> 
            </el-input>
            <span>共{{total}}条数据</span>
          </div>
          <div class="btnBox">
            <router-link tag="a" class="authenticationBtn" to="/appealRecord/list" target="_blank">申诉记录</router-link>
            <div class="authenticationBtn" @click="goAuthentication()">认证新应用</div>
          </div>
        </div>

        <div class="listBox" v-if="this.tableData.length>0">
          <div class="item" v-for="(item,index) in tableData" :key="index ">
            <div class="appMsg">
              <div class="appInfo">
                <el-tooltip :content="item.apkName"  placement="top" :disabled="isShowTooltipApkName">
                  <h1 @mouseover="onMouseOverApkName(item.apkName )">
                    <span :ref='item.apkName'>{{item.apkName}}</span>
                  </h1>
                </el-tooltip>
                <div class="term">
                  <p v-if="item.state=='1'" class="underWay">金标认证中</p> 
                  <p v-if="item.state=='2'" class="valid">金标认证有效</p>
                  <p v-if="item.state=='3'" class="fail">金标认证失败</p>
                  <p v-if="item.state=='4'" class="overdue">金标认证已过期</p>
                </div>
                <div class="border" v-if="item.expireTime">
                  有效期至
                  <span>{{item.expireTime | parseTime()}}</span>
                </div>
                <div class="border packageBox" >
                  <p class="packagetitle">应用包名</p>
                  <el-tooltip :content="item.apkPackage"  placement="top" :disabled="isShowTooltipApkPackage">
                    <p class="package" @mouseover="onMouseOverApkPackage(item.apkPackage )" ><span :ref='item.apkPackage'>{{item.apkPackage}}</span></p>
                  </el-tooltip>
                </div>
                <!-- <div class="border">
                  应用信息
                  <span v-if="item.reviewState=='1'" class="examine">审核中</span>
                  <span v-if="item.reviewState=='2'" class="examineYes">审核通过</span>
                  <span v-if="item.reviewState=='3'" class="examineNo">审核不通过</span>
                  <span v-if="item.reviewState=='4'" class="examineNo">已过期</span>
                  </div> -->
                <div>最新版本<span>{{item.versionName}}({{ item.versionCode}})</span></div>
              </div>
              <div class="appIcon">
                <img :src="item.apkIcon" alt="">
              </div>
            </div>
            <!-- <div v-if="item.reason" class="errortips" >
              <el-tooltip effect="dark" :content="item.reason" placement="top">
                <p class="msg">{{item.reason}}</p>
              </el-tooltip>
            </div> -->
            <div class="btn-box">
              <!-- <div class="btn-item" 
                v-if="item.reviewState !='2'" 
                @click="goToAppInfo()">
                  查看应用信息
              </div> -->
              <div class="btn-item"
                v-if="item.firstSendTime==0 && item.state=='3'"
                @click="goAuthentication(item.apkPackage)">
                  重新认证
              </div>
              <div class="btn-item"       
                @click="gotoAppVersionList(item)">
                  版本认证记录
              </div>      
            </div>
          </div>
        </div>
        <div class="listBox" v-if="!this.tableData.length">
          <div class="noDataBox">
             <img class="img" src="../../../assets/img/console/noData.jpg" alt="">
             <div v-if="keyword && !this.tableData.length && isSearch">
              <p class="msg">未能找到“ {{this.msg}} ”相关应用</p>
             </div>
             <div v-if="!isSearch && !this.tableData.length">
              <p class="msg">您还未认证应用，请先发起金标应用认证</p>
              <div class="authenticationBtn" @click="goAuthentication()">认证新应用</div>
             </div>
          </div>
        </div>
        <div class="margin-t30 text-right" v-if="this.tableData.length>0">
          <el-pagination
            class="paginationBox"
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page.sync="page"
            :page-sizes="[6,12,24,36]"
            :page-size="perPage"
            :total="total"
            layout="total, prev, pager, next, sizes">
          </el-pagination>
        </div>
      </div>
    </div>
    <step v-if="isLookStep" :stepSwitch="isLookStep" @closeStep="closeStep"></step>
    <notice-log v-if="!dialog && JSON.stringify(noticeData) != '{}'" :dialogVisible="!dialog" :noticeData="noticeData" @closeNotice="closeNotice"></notice-log>
  </div>
</template>
<script>
import step from "./template/step"; //认证步骤模板
import { singleGoldIdentificationSever } from "@/common/api-config";
import store from '@/store/index.js';
import noticeLog from './template/noticeLog';
export default {
  beforeRouteEnter: (to,from,next) => {
    store.commit("breadcrumbStore/setMenuBreadcrumb", {'menu': to.meta.menuBreadcrumb, name: "金标认证", path: to.fullPath} );
    next()
  },
  components: {
    step,
    noticeLog
  },
  
  data() {
    return {
      dialogVisible:false,
      noticeData:{},
      // isSafari:/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),//是不是苹果浏览器
      // isLookStep: localStorage.getItem("isLookStep"), //存缓存,
      isLookStep: false, //存缓存,
      dialog: localStorage.getItem("dialog"), //存缓存,
      apkInfo: {}, //认证记录需要用到的数据
      keyword: "",
      page: 1,
      perPage: 6,
      total: 0,
      tableData: [] ,//金标list
      isShowTooltipApkPackage: true,
      isShowTooltipApkName: true,
      msg:"",
      isSearch:false,
    };
  },
  async created() {
   await this.queryIdentifyList(); //获取APP应用list  
  },
  methods: {
     onMouseOverApkPackage(str) { // 内容超出，显示文字提示内容
        const tag = this.$refs[str];
        const parentWidth = tag[0].parentNode.offsetWidth;// 获取元素父级可视宽度要减去应用包名的长度
        const contentWidth = tag[0].offsetWidth; // 获取元素可视宽度
        this.isShowTooltipApkPackage = contentWidth <= parentWidth;
    },

    onMouseOverApkName(str) { // 内容超出，显示文字提示内容
        const tag = this.$refs[str];
        const parentWidth = tag[0].parentNode.offsetWidth;// 获取元素父级可视宽度要减去应用包名的长度
        const contentWidth = tag[0].offsetWidth; // 获取元素可视宽度
        this.isShowTooltipApkName = contentWidth <= parentWidth;
    },


    //获取金标认证list 
    queryIdentifyList() {
      singleGoldIdentificationSever
        .queryIdentifyList({
          keyword: this.keyword,
          page: this.page,
          perPage: this.perPage,
        })
        .then(res => {
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.total = res.data.total;
            this.msg=this.keyword;
            this.isSearch=res.data.isSearch;
          
            this.queryNotice(); 
          } else {      
            this.$message.error(res.message);
          }
        });
    },
    //搜索
    search() {
      this.page = 1;
      this.queryIdentifyList();
    },
    onEmpty(){
      if(this.keyword==""){
        this.page = 1;
        this.queryIdentifyList();
      }
    },
    //每页多少条
    sizeChange(val) {
      this.perPage = val;
      this.queryIdentifyList();
    },
    //翻页
    currentChange(val) {
      this.page = val;
      this.queryIdentifyList();
    },
    //认证步骤关闭
    lookStep() {
      this.isLookStep = true;
    },
    closeStep(data){
      this.isLookStep = data;
    },
    closeNotice(data){
      this.dialog = data;
      localStorage.setItem("dialog", data);
    },
    //获取公告信息
    queryNotice(){
      singleGoldIdentificationSever.queryNotice().then(res=>{
        if (res.code == 0 && JSON.stringify(res.data) != "{}"){
          this.noticeData=res.data;
          this.dialogVisible=true;
        }
      })
    },
    //跳转认证
    goAuthentication(item) {
      console.log(this.$store.getters["membersStore/isAllBind"],"1111111111");
      if (this.$store.getters["membersStore/isAllBind"]) {
        let path = item
          ? `/console/goldIdentification/form?apkPackage=${item}`
          : "/console/goldIdentification/form";
        this.$router.push(path); //跳转认证页
      } else {
        this.$msgBox
          .alertMessageBox({
            title: "绑定厂商账号",
            content: "绑定所有厂商开放平台的账号后，才能进行金标认证"
          })
          .then(async data => {})
          .catch(val => {});
      }
    },
    //打开认证记录
    openRecordBox(row) {
      this.apkInfo = row;
      this.$refs.record.open();
    },


    gotoAppVersionList(data){
      this.$router.push({
        path:'appVersionList',
        query:{
          apkPackage:data.apkPackage,
          breadcrumbName:data.apkName
        }
      });
    },
    goToAppInfo(){
      this.$router.push({ path:'/console/application/list'})
    },   
  },
  // beforeRouteLeave(to, from, next) {
  //     //设置下一个路由的meta,让列表页面缓存,即不刷新
  //   to.meta.keepAlive = true;
  //   next();
  // },
};
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content {
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    overflow: hidden;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px;
      font-weight: bold;
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(../../../assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }
      .msgIcon {
        margin: 0 64px 0 12px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &:hover{
          transform:scale(1.5);
          transform:scale(1.5);		
				}
      }     
    }
    .authenticationBtn {
      width: 186px;
      height: 49px;
      background: #28262C;
      border-radius: 10px;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      color: #DED094;
      cursor: pointer;
      margin: 0 10px;
      &:hover{
        background:#d8b656b8;
        color: #000;
      }
    }
    .applicationBox {
      padding:24px 0;
      padding-left: 40px;
      .searchBox{
        display: flex;
        .flex1{
          .searchBtn {
            width:60%;
            height: 48px;
            &:hover{
              opacity: 0.7;
            }
            /deep/ .el-input__inner {
              height: 48px;
            }
            .searchIcon{
              background: #fff;
            }
          }
          span{
            margin-left: 34px;
            color: #666666;
            font-size: 14px;
          }
        }
        
        .btnBox{
          display: flex;
          margin-right: 18px; 
        } 
      }
      .listBox{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        min-height: 400px;
        .item{
          overflow: hidden;
          min-width: 420px;
          width: 30%;
          height: 300px;     
          border-radius: 5px;
          border: 1px solid #DFDFDF;
          position: relative;
          margin-right: 3.3%;
          margin-bottom: 20px;
          padding-bottom: 70px;
          .appMsg{
            padding: 29px 0 0 41px; 
            .appInfo{
              h1{
                cursor: pointer;  
                padding-left: 5px;   
                font-size: 24px;    
                color: #000000;
                font-weight: bold;
                margin-bottom: 12px;
                width:68%;
                overflow:hidden;
                text-overflow:ellipsis;
                -o-text-overflow:ellipsis;
                -webkit-text-overflow:ellipsis;
                -moz-text-overflow:ellipsis;
                white-space:nowrap;
              }
              .term{
                width: 112px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                margin-bottom: 15px;
                p{
                  width: 100%;
                  border-radius: 13px;
                }
                .underWay{            
                  background: #D6F2C9;
                  color: #428F1E;
                }
                .valid{                
                  background: #DED094;
                  color: #795813;
                }
                .fail,.overdue{
                  background: #F6DADD;
                  color: #CF2233;                                  
                }
              }
              div{
                padding-left: 5px;
                width: 250px;
                height: 32px;
                line-height: 32px; 
                color: #333;             
                font-weight: bold;
                font-size: 14px;
                span{
                  color: #666;
                  font-weight: 300;
                  margin-left: 15px;
                  font-weight: bold;
                  width: 100%;
                } 
              }
              .border{  
                border-bottom: 1px solid #ccc;
              }
              .examine{
                color: #795813;
              }
              .examineYes{
                color: #428F1E;
              }
              .examineNo{
                color: #CF2233;
              }
              .packageBox{
                display: flex;
                .packagetitle{
                  margin-right: 15px;
                }
                .package{
                  width: 174px;
                  color: #666666;
                  font-weight: normal;
                  cursor: pointer;
                  overflow:hidden;
                  text-overflow:ellipsis;
                  -o-text-overflow:ellipsis;
                  -webkit-text-overflow:ellipsis;
                  -moz-text-overflow:ellipsis;
                  white-space:nowrap;
                  span{
                    margin-left: 0;
                  }
                }
              }
            }
            .appIcon{
              position: absolute;
              top:29px;
              right:24px;
              width: 100px;
              height: 100px;
              border-radius: 10px;
              img{
                width: 100%;
                height: 100%;
              }
            }  
          }
          .errortips{
            height:40px;
            overflow: hidden;
            .msg{
              // background: #F6DADD;
              color: #CF2233;
              line-height: 25px;
              border-radius: 13px;
              margin: 5px 10px 0;
              padding: 5px 35px;
            }
          }
          .btn-box{
            width: 100%;
            height: 55px;
            display: flex;
            text-align: center;
            line-height: 35px;
            justify-content: space-around;
            align-items: center;
            align-content: center;
            background: #F0F3F6;
            position: absolute;
            bottom: 0;
            color: #333333;
            font-weight: bold;
            .btn-item{
              position: relative;
              flex: 1;
              width: 100%;
              height: 100%;
              line-height: 55px;
              cursor: pointer;
              &:hover{
                font-size: 15px;
              }
            }
            
            .btn-item:nth-child(2){
               &:before{
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 31px;
                background: #B2B2B2;
              } 
            }
          }
        }
        .noDataBox{
          width: 350px;
          height: 300px;
          position: absolute;
          left:50%;
          top:50%;
          transform:translate(-50%,-50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          .img{
            width: 338px;
            height: 211px;
          }
          .msg{
            margin-top: 20px;           
            color: #ccc;
            margin-bottom: 22px;
            font-size: 18px;
          }
          .authenticationBtn{
            margin: auto;
          }
        }
      }
    }  
  }
}
</style>

