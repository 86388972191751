<template>
  <el-dialog
    :visible="dialogVisible"
		:show-close="false"
    width="600px"
		top="200px"
		class="noticeBox">
		<div class="contentBox">
			<div class="imgBox">
				<img :src="noticeData.picUrl" alt="">
			</div>
			<div class="content">
				<p class="title">{{noticeData.title}}</p>
				<div class="text">
					{{noticeData.content}}
				</div>
				<div class="text-center text-center btnBox">
					<a :href="noticeData.link" target="_blank"><el-button v-if="noticeData.link" class="btn look" size="medium" >查&emsp;看</el-button></a>
					<el-button class="btn" size="medium" @click="close()">关&emsp;闭</el-button>
				</div>
			</div>
		</div>
		
	</el-dialog>
</template>

<script>
export default {
	props: {
    dialogVisible:Boolean,
		noticeData:Object
  },
	
	data(){
		return{

		}
	},
	methods:{
		close(){
			this.$emit('closeNotice',true);
		}
	}
}
</script>

<style scoped lang="scss">
.noticeBox{
	border-radius:5px;
	/deep/.el-dialog__header{
		padding: 0;
	}
	/deep/.el-dialog__body{
		padding: 0;
	}
	.contentBox{
		.imgBox{
			width: 600px;
			height: 212px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.content{
			min-height: 200px;
			max-height: 430px;
			padding: 10px 30px;
			position: relative;	
			.title{
				margin-top: 10px;
				width: 100%;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				color: #000;
			}
			.text{
				margin-top: 20px;
				line-height: 26px;
			}
			.btnBox{
				margin-top: 30px;
				margin-bottom: 20px;
				width: 540px;
				.btn{
					width: 151px;
					height: 33px;
					margin: 0 10px;
				}
				a{	
					.look{
						background: #28262C;
						color:#DED094!important;
					}
					&:hover{
						.look{
							background:#d8b656b8;
							color: #000!important
						}						
					}
				}
			}	
		}
	}
	
}
</style>